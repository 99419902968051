<template>
  <Evaluations :type="evaluationTypes.QUIZ">
    <template #title>
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.quizzes", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_evaluations_quizzes") }}
        </VHint>
      </VTitle>
    </template>
  </Evaluations>
</template>

<script>
import { useI18n } from "vue-i18n";
// Components
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import Evaluations from "../Evaluations";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTitle,
    VHint,
    Evaluations
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.learning")}`;

    return {
      documentTitle,
      evaluationTypes
    };
  }
};
</script>
